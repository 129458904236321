import React from 'react'
import Head from 'next/head'

import Footer from './footer'
import { useRouter } from 'next/router'
import Header01 from './header/Header01'
import { GoogleAdsense } from '@/components/google'
import { CookieConsent } from '@/components/cookies'
import Config from '../site.config'

export default function Layout({ children }) {
  const route = useRouter()

  return (
    <>
      <Head>
        <GoogleAdsense pId={Config.google.trackAddSense} />
      </Head>
      <div>
        <Header01 />
        <main>{children}</main>
        <Footer />
      </div>
      <CookieConsent />
    </>
  )
}
