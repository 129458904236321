import Link from 'next/link'

import { footerMenuList, socialIcons } from '../data/footer_data'
import { ShowVersion } from '../components/about'
import Config from '../site.config'

const footer = () => {
  return (
    <>
      {/* <!-- Footer --> */}

      <footer className='absolute w-screen bg-white shadow dark:bg-gray-900 my-4'>
        <div className='w-full max-w-screen-xl mx-auto p-4 md:py-8'>
          <div className='sm:flex sm:place-items-stretch sm:justify-between'>
            <div
              className={` ${
                socialIcons.length === 0
                  ? 'hidden'
                  : 'hidden md:flex lg:w-1/4 md:w-1/3'
              }`}
            >
              {/* <!-- Logo --> */}
              <Link href='#' className='mb-6'>
                <img
                  src='/images/logo.png'
                  className='h-20 mt-2 dark:hidden mx-auto'
                  alt=''
                />
              </Link>

              <Link href='#' className='mb-6'>
                <img
                  src='/images/logo_white.png'
                  className='hidden h-20 dark:block mt-2 mx-auto'
                  alt=''
                />
              </Link>
            </div>

            {footerMenuList.map((single) => (
              <div className='w-full sm:w-1/2 lg:w-1/4 px-2' key={single.id}>
                <h3 className='font-display text-jacarta-700 mb-6 mt-4 text-base dark:text-white'>
                  {single.title}
                </h3>
                <ul className='dark:text-jacarta-300 flex flex-col space-y-1'>
                  {single.list.map((item) => {
                    const { id, href, text } = item
                    return (
                      <li key={id}>
                        <Link
                          href={href}
                          className='group-hover:fill-accent dark:hover:text-white'
                        >
                          {text}
                        </Link>
                      </li>
                    )
                  })}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <hr className=' border-gray-200 sm:mx-auto dark:border-gray-700 ' />
        <div className='w-full max-w-screen-xl mx-auto p-4 md:py-8'>
          <div className='sm:flex sm:items-center sm:justify-between'>
            <span className='text-sm text-gray-500 sm:text-center dark:text-gray-400'>
              <span className='group-hover:fill-accent dark:hover:text-white'>
                <Link href={Config.site.URL}>
                  © {new Date().getFullYear()} {Config.site.name}. All Rights
                  Reserved
                </Link>
              </span>
            </span>
            {/* Socials */}
            <div className='flex mt-4 justify-between sm:justify-center sm:mt-0'>
              <div className='flex items-center'>
                {socialIcons.map((item) => {
                  const { id, href, text } = item
                  return (
                    <Link
                      href={href}
                      key={id}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='group cursor-pointer mx-2'
                    >
                      <svg
                        fill='currentColor'
                        className='icon group-hover:fill-accent fill-jacarta-300 h-6 w-6 dark:group-hover:fill-white'
                      >
                        <use xlinkHref={`/icons.svg#icon-${text}`} />
                      </svg>
                    </Link>
                  )
                })}
              </div>
            </div>
          </div>
          <ShowVersion />
        </div>
      </footer>
    </>
  )
}

export default footer
