const seoConfig = {
  openGraph: {
    type: 'website',
    locale: 'es_MX',
    url: 'https://romikya.mx/',
    site_name: 'Romikya Labs',
    canonical: 'https://romikya.mx/',
  },
  twitter: {
    handle: '@romikyalabs',
    site: '@romikyalabs',
    cardType: 'summary_large_image',
  },
}
export default seoConfig
