import React from 'react'

import CookieConsent from 'react-cookie-consent'

const Cookie = () => {
  return (
    <CookieConsent
      location='bottom'
      buttonText='Aceptar'
      cookieName='useCookies'
      buttonClasses='btn-consent'
      disableButtonStyles={true}
      style={{ background: '#131740' }}
      buttonWrapperClasses='btn-consent-wrapper'
      expires={150}
    >
      Este sitio web para mejorar la experiencia del usuario utiliza cookies
      propias y de terceros con fines técnicos, analíticos, de personalización,
      redes sociales y/o para mostrarte publicidad personalizada. Al permanecer
      en el sitio o navegar en él, da su consentimiento para su uso.
    </CookieConsent>
  )
}

export default Cookie
