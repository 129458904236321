import Head from 'next/head'

type Keyword = {
  name: string
  content: string
}

interface IProps {
  title: string
  keyword: string
  canonicalURL?: string
  keyList?: Keyword[]
}

const Meta = ({ title, keyword, canonicalURL, keyList }: IProps) => {
  const canonical = canonicalURL ? (
    <link rel='canonical' href={canonicalURL} />
  ) : null

  return (
    <div>
      <Head>
        <title>{title}</title>
        {keyList &&
          keyList.map(({ name, content }, index) => (
            <meta name={name} content={content} key={index} />
          ))}
        <meta name='keyword' content={keyword} />
        {canonical}
      </Head>
    </div>
  )
}

Meta.defaultProps = {
  title: 'Romikya',
  keyword: 'Desarrollo web',
  canonicalURL: null,
}

export default Meta
