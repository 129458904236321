import * as React from 'react'
import Script from 'next/script'

interface IProps {
  pId: string
}

const GoogleAdsense: React.FC<IProps> = ({ pId }: IProps) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('ENV: ', process.env.NODE_ENV)
    return null
  }

  return (
    <Script
      async
      src={`https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-${pId}`}
      crossOrigin='anonymous'
      strategy='afterInteractive'
    />
  )
}

export default GoogleAdsense
