import '../styles/globals.css'
import { useRef, useEffect } from 'react'
import { ThemeProvider } from 'next-themes'
import { useRouter } from 'next/router'
import { DefaultSeo } from 'next-seo'

import Layout from '@/components/layout'
import Meta from '@/components/Meta'
import UserContext from '@/components/UserContext'

import DEFAULT_SEO_CONFIG from '../next-seo.config'
import Config from '../site.config'

function MyApp({ Component, pageProps }) {
  const { site } = Config
  const router = useRouter()
  const pid = router.asPath
  const scrollRef = useRef({
    scrollPos: 0,
  })
  useEffect(() => {
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (
      localStorage.theme === 'dark' ||
      (!('theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }, [])
  const p = router.asPath.slice(1)
  const canonicalURL = `${site.URL}/${p}`.split('?')[0]

  return (
    <>
      <Meta title={site.title} canonicalURL={canonicalURL} />
      <ThemeProvider enableSystem={true} attribute='class'>
        <DefaultSeo
          {...DEFAULT_SEO_CONFIG}
          title={site.title}
          description={site.description}
        />
        <UserContext.Provider value={{ scrollRef: scrollRef }}>
          {pid === '/login' ? (
            <Component {...pageProps} />
          ) : (
            <Layout>
              <Component {...pageProps} />
            </Layout>
          )}
        </UserContext.Provider>
      </ThemeProvider>
    </>
  )
}

export default MyApp
