import Image from 'next/image'
import Link from 'next/link'
import DarkMode from '../mode/DarkMode'
import Logo from './../../public/images/logo.png'
import WhiteLogo from './../../public/images/logo_white.png'
import { v4 as uuidv4 } from 'uuid'
import { useRouter } from 'next/router'
import {
  isChildrenPageActive,
  isParentPageActive,
} from '../../utils/daynamicNavigation'
import { useEffect, useState } from 'react'
import SiteConfig from '../../site.config'

export default function Header01() {
  const [toggle, setToggle] = useState(false)
  const [isCollapse, setCollapse] = useState(null)

  // window resize
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (window.innerWidth >= 1024) {
        setToggle(false)
      }
    })
  })

  const route = useRouter()
  /* -------------------------------------------------------------------------- */
  /*                            daynamic navigations                            */
  /* -------------------------------------------------------------------------- */
  const home = {
    id: 1,
    name: 'Home',
    pages: [
      {
        id: uuidv4(),
        name: 'Crypto Consultant',
        condition: true,
        path: '/home/home_7',
      },
    ],
  }

  const page = {
    id: 2,
    name: 'Pages',
    pages: [
      {
        id: uuidv4(),
        name: 'Contact',
        path: '/contact',
      },
    ],
  }

  const resource = {
    id: 4,
    name: 'Menu',
    pages: [
      {
        id: uuidv4(),
        name: 'Inicio',
        path: '/',
      },
      {
        id: uuidv4(),
        name: 'Contacto',
        path: '/contact',
      },
      {
        id: uuidv4(),
        name: 'Blog',
        path: '/blog',
      },
      {
        id: uuidv4(),
        name: 'Cursos',
        path: '/courses',
      },
      {
        id: uuidv4(),
        name: 'Servicios',
        path: '/services',
      },
      {
        id: uuidv4(),
        name: 'Términos y Condiciones',
        path: '/terminos-condiciones',
      },
      {
        id: uuidv4(),
        name: 'Políticas de privacidad',
        path: '/politicas-privacidad',
      },
    ],
  }

  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null)
    }
    setCollapse(id)
  }

  return (
    <>
      {/* main desktop menu sart*/}
      <header className='js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors py-3'>
        <div className='flex items-center px-6 py-0 xl:px-24'>
          <Link className='shrink-0' href='/'>
            <div className='dark:hidden'>
              <Image
                src={Logo}
                height={80}
                priority={true}
                alt='Romikya Logo'
              />
            </div>
            <div className='hidden dark:block'>
              <Image
                src={WhiteLogo}
                height={80}
                priority={true}
                alt='Romikya Logo'
              />
            </div>
          </Link>
          {/* End  logo */}

          <div className='js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent'>
            <nav className='navbar w-full'>
              <ul className='flex flex-col lg:flex-row'>
                {/* home */}
                <Link href='/'>
                  <li className='js-nav-dropdown group relative'>
                    <button className='dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-lg dark:text-white lg:px-5 w-full'>
                      <span
                        className={
                          isParentPageActive(home.pages, route.asPath)
                            ? 'text-accent  dark:text-accent'
                            : ''
                        }
                      >
                        Inicio
                      </span>
                      <i className='lg:hidden'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          width={24}
                          className='h-4 w-4 dark:fill-white'
                        >
                          <path fill='none' d='M0 0h24v24H0z' />
                          <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                        </svg>
                      </i>
                    </button>
                  </li>
                </Link>
                <Link href='/contact'>
                  {/* page */}
                  <li className='js-nav-dropdown group relative'>
                    <button className='dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-lg dark:text-white lg:px-5 w-full'>
                      <span
                        className={
                          isParentPageActive(page.pages, route.asPath)
                            ? 'text-accent dark:text-accent'
                            : ''
                        }
                      >
                        Contacto
                      </span>
                      <i className='lg:hidden'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          width={24}
                          height={24}
                          className='h-4 w-4 dark:fill-white'
                        >
                          <path fill='none' d='M0 0h24v24H0z' />
                          <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                        </svg>
                      </i>
                    </button>
                  </li>
                </Link>

                <Link href='/blog'>
                  {/* resource */}
                  <li className='js-nav-dropdown group relative'>
                    <button className='dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-lg dark:text-white lg:px-5 w-full'>
                      <span
                        className={
                          isParentPageActive(home.pages, route.asPath)
                            ? 'text-accent  dark:text-accent'
                            : ''
                        }
                      >
                        Blog
                      </span>
                      <i className='lg:hidden'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          width={24}
                          height={24}
                          className='h-4 w-4 dark:fill-white'
                        >
                          <path fill='none' d='M0 0h24v24H0z' />
                          <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                        </svg>
                      </i>
                    </button>
                  </li>
                </Link>

                <Link href='/courses'>
                  <li className='js-nav-dropdown group relative'>
                    <button className='dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-lg dark:text-white lg:px-5 w-full'>
                      <span
                        className={
                          isParentPageActive(home.pages, route.asPath)
                            ? 'text-accent  dark:text-accent'
                            : ''
                        }
                      >
                        Cursos
                      </span>
                      <i className='lg:hidden'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          width={24}
                          height={24}
                          className='h-4 w-4 dark:fill-white'
                        >
                          <path fill='none' d='M0 0h24v24H0z' />
                          <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                        </svg>
                      </i>
                    </button>
                  </li>
                </Link>

                <Link href='/services'>
                  <li className='js-nav-dropdown group relative'>
                    <button className='dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-lg dark:text-white lg:px-5 w-full'>
                      <span
                        className={
                          isParentPageActive(home.pages, route.asPath)
                            ? 'text-accent  dark:text-accent'
                            : ''
                        }
                      >
                        Servicios
                      </span>
                      <i className='lg:hidden'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 24 24'
                          width={24}
                          height={24}
                          className='h-4 w-4 dark:fill-white'
                        >
                          <path fill='none' d='M0 0h24v24H0z' />
                          <path d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' />
                        </svg>
                      </i>
                    </button>
                  </li>
                </Link>
              </ul>
            </nav>
            {/* End menu for desktop */}

            <div className='ml-8 hidden items-center lg:flex xl:ml-12'>
              <DarkMode />
            </div>
            {/* End header right content  for desktop */}
          </div>
          {/* header menu conent end for desktop */}

          <div className='ml-auto flex lg:hidden'>
            <DarkMode />
            <button
              className='js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
              aria-label='open mobile menu'
              onClick={() => setToggle(true)}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 24 24'
                width={24}
                height={24}
                className='fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
              >
                <path fill='none' d='M0 0h24v24H0z' />
                <path d='M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z' />
              </svg>
            </button>
          </div>
          {/* End header right content  for mobile */}
        </div>
        {/* End flex item */}
      </header>
      {/* main desktop menu end */}

      {/* start mobile menu and it's other materials  */}
      <div
        className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
          toggle ? 'nav-menu--is-open' : 'hidden'
        }`}
      >
        <div className='t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden'>
          <div className='dark:hidden'>
            <Image src={Logo} height={55} width={55} alt='Romikya Logo' />
          </div>

          <div className='hidden dark:block'>
            <Image src={WhiteLogo} height={55} width={55} alt='Romikya Logo' />
          </div>

          <button
            className='js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]'
            onClick={() => setToggle(false)}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 24 24'
              width={24}
              height={24}
              className='fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white'
            >
              <path fill='none' d='M0 0h24v24H0z' />
              <path d='M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z' />
            </svg>
          </button>
        </div>
        ---
        {/* mobile menu top header content */}
        <nav className='navbar w-full'>
          <ul className='flex flex-col lg:flex-row'>
            {home?.pages?.map((page) => (
              <li key={page.id} onClick={() => setToggle(false)}>
                <Link href={page.path}>
                  <span className='dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors justify-between'>
                    <span
                      className={`font-display ${
                        isChildrenPageActive(route.asPath, page.path)
                          ? 'text-accent dark:text-accent'
                          : 'text-jacarta-700'
                      } text-base dark:text-white`}
                    >
                      {page.name}
                    </span>
                    {page.condition ? (
                      <span className='rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4'>
                        new
                      </span>
                    ) : undefined}
                  </span>
                </Link>
              </li>
            ))}
            {page?.pages?.map((page) => (
              <li key={page.id} onClick={() => setToggle(false)}>
                <span className='dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors justify-between'>
                  <Link href={page.path}>
                    <span
                      className={
                        isChildrenPageActive(page.path, route.asPath)
                          ? 'text-accent dark:text-accent'
                          : ''
                      }
                    >
                      {page.name}
                    </span>
                    {page.condition ? (
                      <span className='rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4'>
                        new
                      </span>
                    ) : undefined}
                  </Link>
                </span>
              </li>
            ))}
            {resource?.pages?.map((page) => (
              <li key={page.id} onClick={() => setToggle(false)}>
                <span className='dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors'>
                  <Link href={page.path}>
                    <span
                      className={`font-display text-jacarta-700 text-lg my-1 dark:text-white ${
                        isChildrenPageActive(page.path, route.asPath)
                          ? 'text-accent dark:text-accent'
                          : ''
                      }`}
                    >
                      {page.name}
                    </span>
                  </Link>
                </span>
              </li>
            ))}
          </ul>
        </nav>
        {/* End navbar mobile menu */}
        <div className='mt-10 w-full lg:hidden'>
          <hr className='dark:bg-jacarta-600 bg-jacarta-100 my-5 h-px border-0' />
          <div className='flex items-center justify-center space-x-5'>
            <a className='group Github' href={SiteConfig.social.github}>
              <svg
                aria-hidden='true'
                focusable='false'
                data-prefix='fab'
                data-icon='github'
                className='group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white'
                role='img'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 512 512'
              >
                <path d='M256 8C119 8 8 119 8 256c0 113.1 73.61 209.4 175.1 243.6 12.8 2.4 17.6-5.6 17.6-12.4 0-6.2-0.2-26.8-0.4-48.6-71.4 15.6-86.4-34.4-86.4-34.4-11.6-29-28.2-36.8-28.2-36.8-23-15.7 1.7-15.4 1.7-15.4 25.5 1.8 38.9 26.2 38.9 26.2 22.7 38.9 59.5 27.7 74.1 21.2 2.3-16.5 8.8-27.7 16-34.2-56.1-6.4-115.3-27.9-115.3-124.4 0-27.5 9.8-49.9 26-67.6-2.6-6.3-11.3-31.9 2.5-66.6 0 0 21.2-6.8 69.6 25.8 20.2-5.6 41.8-8.4 63.2-8.5 21.4 0.1 43.1 2.9 63.2 8.5 48.4-32.6 69.6-25.8 69.6-25.8 14 34.7 5.3 60.3 2.6 66.6 16.3 17.7 26 40.1 26 67.6 0 96.6-59.4 118.9-115.5 125 9.1 7.9 17.2 23.4 17.2 47.2 0 34.1-0.3 61.5-0.3 69.8 0 6.8 4.5 14.7 17.7 12.3C436.4 465.4 504 369.1 504 256c0-137-111-248-248-248z'></path>
              </svg>
            </a>
            <a className='group twiteer' href={SiteConfig.social.twitter}>
              <svg
                aria-hidden='true'
                focusable='false'
                data-prefix='fab'
                data-icon='twitter'
                className='group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white'
                role='img'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 512 512'
              >
                <path d='M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z' />
              </svg>
            </a>
            <a className='group Linkeding' href={SiteConfig.social.linkedin}>
              <svg
                aria-hidden='true'
                focusable='false'
                data-prefix='fab'
                data-icon='linkedin'
                className='group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white'
                role='img'
                xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 640 512'
              >
                <path d='M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z' />
              </svg>
            </a>
          </div>
        </div>
        {/* mt-10 w-full lg:hidden */}
      </div>
      {/* End mobile menu and it's other materials */}
    </>
  )
}
