const Config = {
  site: {
    name: 'Romikya Labs',
    URL: 'https://romikya.mx',
    title: 'Romikya Labs',
    logo: '/images/logo.png',
    preview: '/preview.png',
    description:
      'Somos un laboratorio de software enfocado en crear un entorno de trabajo diseñado para la investigación, desarrollo, prueba y mejora de software.',
    metaTags: [
      { name: 'google-adsense-account', content: 'ca-pub-3432671328017658' },
    ],
  },
  social: {
    twitter: 'https://twitter.com/@romikyalabs',
    github: 'https://github.com/romikya',
    linkedin: 'https://www.linkedin.com/company/romikya-labs',
  },
  google: {
    trackGA: '',
    trackGTM: '',
    trackAW: '',
    trackAddSense: '3432671328017658',
  },
}

export default Config