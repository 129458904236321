const footerMenuList = [
  {
    id: 1,
    title: "Legal",
    diffClass: "md:col-start-7",
    list: [
      {
        id: 1,
        href: "/terminos-condiciones",
        text: "Términos y condiciones",
      },
      {
        id: 2,
        href: "/politicas-privacidad",
        text: "Políticas de privacidad",
      }
    ],
  },
  {
    id: 2,
    title: "Nosotros",
    diffClass: "",
    list: [
      {
        id: 1,
        href: "/contact",
        text: "Contacto",
      },
      {
        id: 2,
        href: "/blog",
        text: "Nuestro Blog",
      },
      {
        id: 3,
        href: 'https://www.alamexicana.dev',
        text: "A La Mexicana",
      },
    ],
  },

];

const socialIcons = [
  {
    id: 1,
    href: "https://github.com/romikya",
    text: "github",
  },
  {
    id: 2,
    href: "https://twitter.com/RomikyaLabs",
    text: "twitter",
  },
  {
    id: 3,
    href: "https://www.linkedin.com/company/romikya-labs/",
    text: "linkedin",
  }

];

export { footerMenuList, socialIcons };
